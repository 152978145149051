import React from "react";
import ReactDOM from "react-dom";
import App from "./layout/AppLayout";
import reportWebVitals from "./reportWebVitals";
import { CookiesProvider } from "react-cookie";
import { BrowserRouter } from "react-router-dom";
import { LocalizeProvider } from "./hooks/useLocalize";

// Importing Sass with Bootstrap CSS
import "./css/app.scss";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <CookiesProvider>
        <LocalizeProvider>
          <App />
        </LocalizeProvider>
      </CookiesProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import * as React from "react";
import { useLocalize } from "../hooks/useLocalize";
import { useTitle } from "../hooks/useTitle";

export const ImprintPage = () => {
  const { strings } = useLocalize();
  useTitle(strings.menu.imprint);

  return (
    <React.Fragment>
      <h2>{strings.menu.imprint}</h2> <br />
      <h3>{strings.imprint.tmg5Header} </h3>
      {process.env.REACT_APP_RESPONSIBLE}
      <br />
      <br />
      {/*   Europaplatz 5, 63225, Langen */}
      <h3>{strings.commmon.contact} </h3>
      {strings.menu.email}: {process.env.REACT_APP_RESPONSIBLE_EMAIL} <br />
      <br /> <h3>{strings.imprint.vatIdTitle}</h3>
      {strings.imprint.vatIdLabel}: {process.env.REACT_APP_VAT_ID} <br />
      {/* Angaben zur Berufshaftpflichtversicherung
Name und Sitz des Versicherers:
Hiscox SA
Arnulfstraße 31
80636 München 

Geltungsraum der Versicherung:
Es besteht weltweiter Versicherungsschutz, mit der Ausnahme von Ansprüchen, die vor Gerichten der USA oder Kanadas geltend gemacht werden oder auf der Verletzung des Rechts dieser Staaten beruhen.
*/}
      <br /> <h3>{strings.imprint.responsibleTitle}</h3>
      {process.env.REACT_APP_RESPONSIBLE}
    </React.Fragment>
  );
};

import { IAppStrings } from "./stringsDef";

export const enStrings: IAppStrings = {
  app: {
    title: process.env.REACT_APP_CLIENT_NAME,
  },
  menu: {
    home: "Home",
    email: "Email",
    linkedin: "LinkedIn",
    xing: "Xing",
    privacy: "Privacy",
    imprint: "Imprint"
  },
  skills: {
    softwareDev: "Software Development",
    digitalization: "Digital Transformation",
    integration: "Integration",
    migration: "Migration",
    consultancy: "Consulting",
    softwareDevDesc: "Developing individual solutions from the requirements stage to the production by choosing the most suitable technology stack  and assuring the quality, performance, extensibility, scalability and security.",
    digitalizationDesc: "Analysing the workflows managed manually via Emails, Excel etc. and  providing modern solutions such as desktop, mobile or web applications which improve the data quality and consistency and enhance the productivity by increasing  the automation as far as possible.",
    integrationDesc: "Integrating independent systems to each other to synchronise the master data  to avoid manuell user inputs by using/implementing various technologies from on-promise solutions to cloud integration services.",
    migrationDesc: "Either migrating the data from a storage to another one by applying all required transformations regarding to the technical and business requirements of the target system or migrating the technology stack and architecture of the existing systems to the cut-edge counterparts, such as monolithic architecture to micro services, WinForm to WPF, WebForms to SPA (ReactJS, Angular etc), on-premise to cloud services, containerisation etc.",
    consultancyDesc: "Supporting existing teams by participating in the development or taking over the whole technical leading or development.",
  },
  imprint: {
    tmg5Header: "Information according to § 5 TMG",
    vatIdTitle: "VAT ID",
    vatIdLabel: "VAT identification number according to § 27a VAT law",
    responsibleTitle: "Responsible for the content according to § 55 para. 2 RStV",
  },
  commmon: {
    contact: "Contact"
  },
  footer: {
    copyright: "All rights reserved ®",
  },
};

import React, { useState } from "react";
import { useCookies } from "react-cookie";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { trimEndTrailingSlashes } from "../common/utils";
import strings, { defaultLang, langugesList } from "../i18n/strings";
import { IAppStrings } from "../i18n/stringsDef";

export interface ILocalizeContext {
  language: string;
  strings: IAppStrings;
  setLanguage: (lng: string) => void;
  localizePath: (path: string) => string;
}

export const LocalizeContext = React.createContext<ILocalizeContext>({
  language: defaultLang,
  strings: {} as IAppStrings,
  setLanguage: (lng: string) => undefined,
  localizePath: (path: string) => path,
});

export const LocalizeConsumer = LocalizeContext.Consumer;

const findCurrentLang = (urlLang?: string, cookieLang?: string): string => {
  return urlLang ?? cookieLang ?? defaultLang;
};

export const LocalizeProvider = (props: any) => {
  const [langCookie, setLangCookie] = useCookies(["lang"]);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const matchParams = matchPath("/:urlLang/*", pathname);

  const { lang } = langCookie;

  const [language, setLanguage] = useState(
    findCurrentLang(matchParams?.params?.urlLang, lang)
  );

  React.useEffect(() => {
    const urlLang = langugesList.filter(
      (x) => x.key === matchParams?.params?.urlLang
    )[0];

    if (!urlLang) {
      setLangCookie("lang", defaultLang, { path: "/" });
      strings.setLanguage(defaultLang);
      setLanguage(defaultLang);
      navigate("/" + defaultLang, { replace: true });
    }
  }, [matchParams?.params?.urlLang, navigate, setLangCookie]);

  setLangCookie("lang", language, { path: "/" });
  strings.setLanguage(language);

  const context = {
    language: language,
    strings: strings,
    setLanguage: (lng: string) => {
      if (language === lng) {
        return;
      }

      let path = pathname.replace("/" + language, "/" + lng);

      setLanguage(lng);

      navigate(trimEndTrailingSlashes(path) ?? "/", { replace: true });
    },
    localizePath: (path: string) => {
      return trimEndTrailingSlashes(`/${language}${path}`);
    },
  } as ILocalizeContext;

  return (
    <LocalizeContext.Provider value={context}>
      {props.children}
    </LocalizeContext.Provider>
  );
};

export const useLocalize = () => React.useContext(LocalizeContext);

import { useEffect } from "react";
import { useLocalize } from "./useLocalize";

export function useTitle(title: string, prependAppTitle: boolean = true) {
  const { strings } = useLocalize();

  useEffect(() => {
    const prevTitle = document.title;

    let fixedTitle = title;
    if (prependAppTitle) {
      fixedTitle = strings.app.title + " - " + title;
    }
    document.title = fixedTitle;
    return () => {
      document.title = prevTitle;
    };
  });
}

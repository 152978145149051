import React from "react";
import { Nav, Navbar, Container } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { navigationItems } from "../common/navigationConfig";
import { useLocalize } from "../hooks/useLocalize";

export const NavigationItems = () => {
  const location = useLocation();
  const { strings, localizePath } = useLocalize();

  return (
    <React.Fragment>
      <Navbar
        sticky="top"
        collapseOnSelect
        expand="md"
        bg="light"
        variant="light"
      >
        <Container fluid>
          <Navbar.Brand as={Link} to={localizePath("/")}>
            <img
              src="/favicon-32x32.png"
              className="me-2"
              alt={strings.app.title}
            ></img>
            {strings.app.title}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              {navigationItems
                .filter((x) => !x.onlyRoote)
                .map((item, index) => {
                  const localize = strings.menu as any;
                  const localizedTitle = localize[item.key];
                  const active = item.path === location.pathname;
                  return (
                    <Nav.Item
                      className={
                        "d-inline-flex" +
                        (item.icon ? " navItemWithIcon" : "") +
                        (active ? " activeNavItem" : "")
                      }
                      key={"navItem" + item.key}
                    >
                      {item.path && (
                        <Nav.Link
                          disabled={active}
                          active={active}
                          eventKey={item.key}
                          as={Link}
                          to={localizePath(item.path)}
                          title={localizedTitle}
                        >
                          {item.icon()}
                          <span className="d-md-none d-lg-inline-block">
                            {localizedTitle}
                          </span>
                        </Nav.Link>
                      )}

                      {item.link && (
                        <Nav.Link
                          eventKey={item.key}
                          href={item.link}
                          target="_blank"
                          title={localizedTitle}
                        >
                          {item.icon()}
                          <span className="d-md-none d-lg-inline-block">
                            {localizedTitle}
                          </span>
                        </Nav.Link>
                      )}
                    </Nav.Item>
                  );
                })}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </React.Fragment>
  );
};

export const getBrowserLocales = (options: { languageCodeOnly: boolean } = { languageCodeOnly: false }) => {
    const defaultOptions = {
        languageCodeOnly: false,
    };

    const opt = {
        ...defaultOptions,
        ...options,
    };
    const browserLocales =
        navigator.languages === undefined
            ? [navigator.language]
            : navigator.languages;
    if (!browserLocales) {
        return undefined;
    }
    return browserLocales.map(locale => {
        const trimmedLocale = locale.trim();
        return opt.languageCodeOnly
            ? (trimmedLocale.split(/-|_/)[0])?.toLocaleLowerCase()
            : trimmedLocale?.toLocaleLowerCase();
    });
}

export const trimEndTrailingSlashes = (input?: string): string | undefined => {
    return input?.replace(/\/$/, "");
}
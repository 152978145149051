import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { navigationItems } from "../common/navigationConfig";
import { defaultLang } from "../i18n/strings";

export const NavigationRoutes = () => {
  return (
    <Routes>
      {navigationItems
        .filter((x) => x.path && x.component)
        .map((item, index) => {
          return (
            <Route
              key={"route" + index}
              path={`/:urlLang${item.path}`}
              element={item.component}
            />
          );
        })}
      <Route path="*" element={<Navigate to={"/" + defaultLang} />} />
    </Routes>
  );
};

import { IAppStrings } from "./stringsDef";

export const trStrings: IAppStrings = {
  app: {
    title: process.env.REACT_APP_CLIENT_NAME,
  },
  menu: {
    home: "Anasayfa",
    email: "Email",
    linkedin: "LinkedIn",
    xing: "Xing",
    privacy: "Gizlilik",
    imprint: "Künye"
  },
  skills: {
    softwareDev: "Yazılım  Geliştirme",
    digitalization: "Dijital Dönüşüm",
    integration: "Entegrasyon",
    migration: "Migrasyon",
    consultancy: "Danışmanlık",
    softwareDevDesc: "En uygun teknolojileri seçerek; kalite, performans, genişletilebilirlik, ölçeklenebilirlik ve güvenlik kaygılarını ön planda tutan özgün çözümleri gereksinim aşamasından ürün safhasına kadar sorumluluk üstlenerek geliştirmek.",
    digitalizationDesc: "E-posta, Excel vb. ile manuel olarak yönetilen iş akışlarını analiz etmek ve otomasyonu mümkün olduğunca artırarak veri kalitesini ve tutarlılığını artıran ve verimliliği artıran masaüstü, mobil veya web uygulamaları gibi modern çözümler sunmak.",
    integrationDesc: "Bağımsız sistemleri kullanıcı etkileşimi gerekmeden, yerinde (on-premıse) çözümlerden bulut servislerine kadar çeşitli teknolojileri kullanarak/uygulayarak birbiriyle entegre etmek.",
    migrationDesc: "Verileri bir depolamadan diğerine, hedef sistemin teknik ve iş gereksinimleriyle ilgili tüm gerekli dönüşümleri uygulayarak taşımak veya mevcut yazılımların teknolojilerini  en son muadillerine yükseltmek (WinForm'dan WPF'ye, WebForms'dan  ReactJS, Angular gibi SPA'ya, on-premise sistemlerden bulut servislerine vb.) ve mimarilerini modernize etmek (monolitikten mikroservis mimarisine geçirmek vb.).",
    consultancyDesc: "Mevcut yazılım ekiplerine katılarak geliştirmeye destek olmak, ya da ekibin veya  projenin sorumluluğunu üstlenmek.",
   },
   imprint: {
     tmg5Header: "Information according to § 5 TMG",
     vatIdTitle: "VAT ID",
     vatIdLabel: "VAT identification number according to § 27a VAT law",
     responsibleTitle: "Responsible for the content according to § 55 para. 2 RStV",
   },
   commmon: {
     contact: "İletişim"
   },
  footer: {
    copyright: "Bütün hakları saklıdır ®",
  },
};

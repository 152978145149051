import React from "react";
import { InfoCircle, ShieldCheck } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { useLocalize } from "../hooks/useLocalize";
import { langugesList } from "../i18n/strings";

export const Footer = () => {
  const { language, setLanguage, strings, localizePath } = useLocalize();

  const handleLanguageChange = (
    event: React.ChangeEvent<{
      value: unknown;
    }>
  ) => {
    var selectedLang = event.target.value as string;
    setLanguage(selectedLang);
  };

  return (
    <footer
      className="d-flex justify-content-between align-items-center p-1"
      style={{
        position: "fixed",
        left: "0",
        bottom: "0",
        width: "100%",
        backgroundColor: "#b3aeae",
        fontSize: "10pt",
        color: "white",
        zIndex: 1205,
        textAlign: "left",
      }}
    >
      <div>
        {strings.footer.copyright}
        {new Date().getFullYear()}
      </div>
      <div className="d-flex justify-content-end  align-items-center  p-0 m-0">
        <div
          title={strings.menu.privacy}
          className="d-flex justify-content-start align-items-center border border-1 my-0 py-0 me-1 p-1"
        >
          <Link to={localizePath("/privacy")}>
            <ShieldCheck className="me-1" />
            <span className="d-none d-sm-inline-block">
              {strings.menu.privacy}
            </span>
          </Link>
        </div>
        <div
          title={strings.menu.imprint}
          className="d-flex justify-content-start align-items-center border border-1 my-0 py-0 me-1 p-1"
        >
          <Link to={localizePath("/imprint")}>
            <InfoCircle className="me-1" />
            <span className="d-none d-sm-inline-block">
              {strings.menu.imprint}
            </span>
          </Link>
        </div>
        <div>
          <select
            value={language}
            className="me-1"
            onChange={handleLanguageChange}
          >
            {langugesList.map((lang) => {
              return (
                <option key={lang.key} value={lang.key}>
                  {lang.name}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    </footer>
  );
};

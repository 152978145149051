import LocalizedStrings from "react-localization";
import { IStrings } from "./stringsDef";
import { deStrings } from "./stringsDE";
import { trStrings } from "./stringsTR";
import { enStrings } from "./stringsEN";
import { getBrowserLocales } from "../common/utils";

let tempLang = "en";

const userBrowserLangs = getBrowserLocales({ languageCodeOnly: true })

let browserDefLang = userBrowserLangs && userBrowserLangs.length > 0 ? userBrowserLangs[0] : null;

if (browserDefLang === "de") {
  tempLang = "de"
}
else if (browserDefLang === "tr") {
  tempLang = "tr"
}

const strings: IStrings = new LocalizedStrings({
  tr: trStrings,
  en: enStrings,
  de: deStrings,
});

strings.setLanguage(tempLang);

export default strings;

export const defaultLang = tempLang;

export const langugesList = [
  { key: "de", name: "Deutsch" },
  { key: "en", name: "English" },
  { key: "tr", name: "Türkçe" }
]

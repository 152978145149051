import * as React from "react";
import { NavigationItems } from "./Navigation";
import { NavigationRoutes } from "./Routes";
import { Footer } from "./Footer";
import { Container, Row } from "react-bootstrap";

export default function App() {
  return (
    <React.Fragment>
      <Container fluid>
        <Row>
          <NavigationItems />
        </Row>
        <Row>
          <main className="mt-2 pt-2 pb-4 mb-4">
            <NavigationRoutes />
          </main>
        </Row>
      </Container>
      <Footer />
    </React.Fragment>
  );
}

import { IAppStrings } from "./stringsDef";

export const deStrings: IAppStrings = {
  app: {
    title: process.env.REACT_APP_CLIENT_NAME,
  },
  menu: {
    home: "Home",
    email: "E-Mail",
    linkedin: "LinkedIn",
    xing: "Xing",
    privacy: "Datenschutz",
    imprint: "Impressum"
  },
  skills: {
    softwareDev: "Softwareentwicklung",
    digitalization: "Digitale Transformation",
    integration: "Integration",
    migration: "Migration",
    consultancy: "Beratung",
    softwareDevDesc: "Entwicklung individueller Lösungen von der Anforderungsphase bis zur Produktion durch Auswahl des am besten geeigneten Technologie-Stacks und Sicherstellung von Qualität, Leistung, Erweiterbarkeit, Skalierbarkeit und Sicherheit.",
    digitalizationDesc: "Analyse der manuell über E-Mails, Excel usw. verwalteten Arbeitsabläufe und Bereitstellung moderner Lösungen wie Desktop-, Mobil- oder Webanwendungen, die die Datenqualität und -konsistenz verbessern und die Produktivität durch eine möglichst weitgehende Automatisierung steigern.",
    integrationDesc: "Integrierung unabhängiger Systeme zueinander, um die Stammdaten zu synchronisieren und manuelle Benutzereingaben zu vermeiden, indem verschiedene Technologien eingesetzt werden, von On-Promise-Lösungen bis zu Cloud-Integrationsdiensten.",
    migrationDesc: "Entweder die Migration der Daten von einem Datenspeicher zu einem anderen durch Anwendung aller erforderlichen Transformationen hinsichtlich der technischen und fachlichen Anforderungen des Zielsystems oder die Migration des Technologiestacks und der Architektur der bestehenden Systeme zu den modernsten Alternativen, wie z. B. monolithische Architektur zu Microservices, WinForm zu WPF, WebForms zu SPA (ReactJS, Angular usw.), On-Premise zu Cloud Services, Containerisierung usw.",
    consultancyDesc: "Unterstützung bestehender Teams durch die Teilnahme an der Entwicklung oder Übernahme der gesamten technischen Leitung oder Entwicklung.",
  },
  imprint: {
    tmg5Header: "Angaben gemäß § 5 TMG",
    vatIdTitle: "Umsatzsteuer-ID",
    vatIdLabel:"Umsatzsteuer-Identifikationsnummer gemäß § 27a Umsatzsteuergesetz",
    
    responsibleTitle: "Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV"
  },
  commmon: {
    contact: "Kontakt"
  },
  footer: {
    copyright: "Alle Rechte vorbehalten ®",
  },
};

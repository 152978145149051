import * as React from "react";
import { Accordion } from "react-bootstrap";
import {
  CodeSlash,
  Activity,
  ArrowUpRightCircle,
  PersonPlus,
  ArrowsAngleContract,
} from "react-bootstrap-icons";
import { useLocalize } from "../hooks/useLocalize";

export const HomePage = () => {
  const { strings } = useLocalize();

  return (
    <React.Fragment>
      <Accordion className="m-2 skillsAccordion" defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header className="skillHeader">
            <CodeSlash /> {strings.skills.softwareDev}
          </Accordion.Header>
          <Accordion.Body>{strings.skills.softwareDevDesc}</Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header className="skillHeader">
            <Activity /> {strings.skills.digitalization}
          </Accordion.Header>
          <Accordion.Body>{strings.skills.digitalizationDesc}</Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header className="skillHeader">
            <ArrowsAngleContract />
            {strings.skills.integration}
          </Accordion.Header>
          <Accordion.Body>{strings.skills.integrationDesc}</Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header className="skillHeader">
            <ArrowUpRightCircle />
            {strings.skills.migration}
          </Accordion.Header>
          <Accordion.Body>{strings.skills.migrationDesc}</Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header className="skillHeader">
            <PersonPlus />
            {strings.skills.consultancy}
          </Accordion.Header>
          <Accordion.Body>{strings.skills.consultancyDesc}</Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <div className="d-flex justify-content-center">
      <a
          target="_blank"
          rel="noreferrer"
          title="iSAQB® CPSA Foundation Level"
          href="https://www.certible.com/badge/3fb62c47-4309-4116-b9f5-573a2a32d351/"
        >
          <img
            style={{ width: "200px" }}
            alt="iSAQB® CPSA Foundation Level"
            src="/cpsa-f-certified-badge.png"
          ></img>
        </a>

        <a
          target="_blank"
          rel="noreferrer"
          title="Azure Solutions Architect Expert"
          href="https://www.credly.com/badges/5025de63-0cb8-4102-b954-dc682cf14c85"
        >
          <img
            style={{ width: "200px" }}
            alt="Azure Solutions Architect Expert"
            src="/azure-solutions-architect-expert.png"
          ></img>
        </a>

        <a
          target="_blank"
          rel="noreferrer"
          title="Azure Data Scientist Associate"
          href="https://www.credly.com/badges/d5268ed2-af00-4ba8-82cc-dc229e7527a8"
        >
          <img
            style={{ width: "200px" }}
            alt="Azure Data Scientist Associate"
            src="/azure-data-scientist-associate.png"
          ></img>
        </a>
      </div>
    </React.Fragment>
  );
};

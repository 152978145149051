import { HomePage } from "../pages/Home";
import { XingIcon } from "./iconExt";
import { ImprintPage } from "../pages/Imprint";
import { PrivacyPage } from "../pages/Privacy";
import { Linkedin, Envelope, House } from "react-bootstrap-icons";

export interface NavigationItem {
  key: string;
  title?: string;
  link?: string;
  component?: React.ReactElement;
  icon?: any;
  path?: string;
  onlyRoote?: boolean;
}

export const navigationItems: NavigationItem[] = [
  {
    key: "home",
    title: "Home",
    path: "/",
    icon: (props: any) => <House {...props} />,
    component: <HomePage />,
  },

  {
    key: "linkedin",
    title: "Linkedin",
    link: process.env.REACT_APP_CONTACT_LINKEDIN,
    icon: (props: any) => <Linkedin {...props} />,
  },

  {
    key: "xing",
    title: "Xing",
    link: process.env.REACT_APP_CONTACT_XING,
    icon: (props: any) => <XingIcon {...props} />,
  },
  {
    key: "email",
    title: "E-Mail",
    link: process.env.REACT_APP_CONTACT_EMAIL,
    icon: (props: any) => <Envelope {...props} />,
  },

  {
    onlyRoote: true,
    key: "imprint",
    path: "/imprint",
    component: <ImprintPage />,
  },
  {
    onlyRoote: true,
    key: "privacy",
    path: "/privacy",
    component: <PrivacyPage />,
  },
];
